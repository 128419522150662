import React, { useState } from 'react'
import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack, useToast } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import { UpdateControl } from '../Queries/Queries'

const ModalRemoveControl = ({ isOpen, onClose, data, token, onDoneUpdate }) => {
    const toast = useToast()
    const [loading, setLoading] = useState(false)


    const onSendCtrl = () => {
        setLoading(true)
        UpdateControl(token, data, -1).then(res => {
            if (res.data) {
                toast({
                    title: "REMOVE CONTROL SUCCESS!",
                    description: res.data.resp,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                setLoading(false)
                onDoneUpdate()
            } else {
                toast({
                    title: "REMOVE CONTROL ERROR!",
                    description: res.response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                setLoading(false)
                onDoneUpdate()
            }
        }).catch(() => {
            toast({
                title: "REMOVE CONTROL ERROR!",
                description: "Ooops! There is something wrong fetching the game controls.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
            setLoading(false)
            onDoneUpdate()
        })
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={"red"} color={'white'}>Removing Control for  {data.gcode}</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <HStack justifyContent={'center'}>
                        <VStack w={'60%'} alignItems={'flex-start'}>
                            <Text
                                fontWeight={'bold'}
                                paddingY={2}
                            >
                                Combi
                            </Text>
                            <Text>Old Control Amount</Text>
                            <Text fontWeight={'bold'}>New Control Amount</Text>
                        </VStack>
                        <VStack w={'30%'}>
                            <Text
                                fontWeight={'bold'}
                                bg={'red'}
                                color={'white'}
                                textAlign={'center'}
                                paddingY={2}
                                paddingX={7}
                                borderRadius={50}
                            >
                                {data.combi}
                            </Text>
                            <Text>{data.ctrl_amt}</Text>

                            <Text
                                bg={"red"}
                                color={"white"}
                                paddingY={2}
                                paddingX={7}
                                borderRadius={50}
                            >
                                Remove
                            </Text>
                        </VStack>
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    {
                        loading ?
                            <Button
                                size={'md'}
                                bg={'red'}
                                color={'white'}
                                mr={3}
                                onClick={() => onSendCtrl()}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                <Spinner size={"sm"} />
                                <Text marginLeft={2}>Removing...</Text>
                            </Button> :
                            <Button
                                size={'md'}
                                bg={'red'}
                                color={'white'}
                                mr={3}
                                onClick={() => onSendCtrl()}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                Remove
                            </Button>
                    }
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalRemoveControl