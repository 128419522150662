import React, { useState } from 'react'
import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, VStack, useToast } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import { UpdateControl } from '../Queries/Queries'

const ModalUpdateControl = ({ isOpen, onClose, data, url, token, onDoneUpdate }) => {
    const toast = useToast()
    const [newCtrl, setnewCtrl] = useState(data.ctrl_amt)
    const [loading, setLoading] = useState(false)


    const onSendCtrl = () => {
        setLoading(true)
        UpdateControl(token, data, newCtrl).then(res => {
            if (res.data) {
                toast({
                    title: "CONTROL UPDATE SUCCESS!",
                    description: res.data.resp,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                setLoading(false)
                onDoneUpdate()
            } else {
                toast({
                    title: "CONTROL UPDATE ERROR!",
                    description: res.response.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                setLoading(false)
                onDoneUpdate()
            }
        }).catch(() => {
            toast({
                title: "CONTROL UPDATE ERROR!",
                description: "Ooops! There is something wrong fetching the game controls.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
            setLoading(false)
            onDoneUpdate()
        })
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={"yellow.500"} color={'white'}>Update Controls for  {data.gcode}</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    {/* <Box h={"200px"} bg={"yellow"} w={"200px"} borderRadius={100} marginTop={-100} marginLeft={-100}>
                        <Text>Hellow</Text>
                    </Box> */}
                    <HStack justifyContent={'center'}>
                        <VStack w={'60%'} alignItems={'flex-start'}>
                            <Text
                                fontWeight={'bold'}
                                paddingY={2}
                            >
                                Combi
                            </Text>
                            <Text>Old Control Amount</Text>
                            <Text fontWeight={'bold'}>New Control Amount</Text>
                        </VStack>
                        <VStack w={'30%'}>
                            <Text
                                fontWeight={'bold'}
                                bg={"yellow.500"}
                                color={'white'}
                                textAlign={'center'}
                                paddingY={2}
                                paddingX={7}
                                borderRadius={50}
                            >
                                {data.combi}
                            </Text>
                            <Text>{data.ctrl_amt}</Text>
                            <Input
                                autoFocus
                                color={clrs.white}
                                size='md'
                                value={newCtrl}
                                onChange={e => setnewCtrl(e.target.value)}
                                borderColor={clrs.txtinptbrdr}
                                textAlign={'center'}
                                type='number'
                                fontWeight={'bold'}
                            />
                        </VStack>
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    {
                        loading ?
                            <Button
                                size={'md'}
                                bg={'yellow.500'}
                                color={'white'}
                                mr={3}
                                onClick={() => onSendCtrl()}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                <Spinner size={"sm"} />
                                <Text marginLeft={2}>Updating...</Text>
                            </Button> :
                            <Button
                                size={'md'}
                                bg={'yellow.500'}
                                color={'white'}
                                mr={3}
                                onClick={() => onSendCtrl()}
                                w={'130px'}
                                _hover={{ backgroundColor: clrs.bglogin }}
                            >
                                Update
                            </Button>
                    }
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalUpdateControl