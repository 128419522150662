import { Text } from '@chakra-ui/react'
import React from 'react'

const MyLabel = ({ text }) => {
    return (
        <Text
            color={'whiteAlpha.700'}
            fontSize={"15px"}
            fontWeight={'bold'}
            marginX={"15px"}
        >
            {text}
        </Text>
    )
}

export default MyLabel