import { IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import React from 'react'
import { FaPencilAlt, FaTrashAlt, FaFolderPlus } from 'react-icons/fa'
import { clrs } from '../Constants/Constants'

const TableControl = ({ controls, onSelectControl, onSelectRemoveControl, onSelectAddControl }) => {
    return (
        <TableContainer maxHeight={'80vh'} overflowY={'scroll'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            <Table size='sm' color={'white'} >
                <Thead >
                    <Tr borderColor={clrs.lable} borderWidth={2} >
                        <Th color={'white'} py={3}>Combi</Th>
                        <Th color={'white'} >Amount</Th>
                        <Th color={'white'} >
                            Actions
                            <Tooltip
                                label="Add Control"
                                placement="right"
                            >
                                <IconButton
                                    onClick={() => onSelectAddControl()}
                                    marginLeft={"5px"}
                                    boxSize={"30px"}
                                    _hover={{
                                        backgroundColor: 'whiteAlpha.400'
                                    }}
                                    size={'xs'}
                                    bg={'transparent'}
                                    icon={
                                        <FaFolderPlus
                                            color='green'
                                            size={"25px"}
                                        />
                                    }
                                    color={'white'}
                                />
                            </Tooltip>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        controls.length > 0 ? controls.map((ct, idx) => (
                            <Tr key={idx} borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td>{ct.combi}</Td>
                                <Td style={{ textAlign: 'right' }}>{ct.ctrl_amt}</Td>
                                <Td textAlign={'center'}>
                                    <Tooltip
                                        label={"Update Control : " + ct.combi}
                                        placement={'left'}
                                    >
                                        <IconButton
                                            onClick={() => onSelectControl(ct)}
                                            marginRight={"5px"}
                                            _hover={{
                                                backgroundColor: 'whiteAlpha.400'
                                            }}
                                            size={'xs'}
                                            bg={'transparent'}
                                            icon={
                                                <FaPencilAlt
                                                    color='orange'
                                                />
                                            }
                                            color={'white'}
                                        />
                                    </Tooltip>
                                    {
                                        (ct.combi != "all" && ct.combi != "teller" && ct.combi != "max_per_bet") &&
                                        <Tooltip
                                            label={"Remove Control : " + ct.combi}
                                            placement="right"
                                        >
                                            <IconButton
                                                onClick={() => onSelectRemoveControl(ct)}
                                                _hover={{
                                                    backgroundColor: 'whiteAlpha.400'
                                                }}
                                                size={'xs'}
                                                bg={'transparent'}
                                                icon={
                                                    <FaTrashAlt
                                                        color='red'
                                                    />
                                                }
                                                color={'white'}
                                            />
                                        </Tooltip>
                                    }
                                </Td>
                            </Tr>
                        )) :
                            <Tr borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td color={'whiteAlpha.500'} textAlign={'center'} colSpan={3}>No Controls Found.</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}
export default TableControl