import { Box, Text, Input, useToast, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import MyMenu from './MyMenu'
import { UserTypes } from './Users'
import { FetchReports, FetchReportsall, GetLongDate, parseDate } from '../Queries/Queries'
import { Queryparams, clrs } from '../Constants/Constants'
import ReportsTableOverall from './ReportsTableOverall'
import { genPDF_overall, genPDF_pergame } from './PdfMakeTable'
import MyReportType, { ReportType } from './MyReportType'
import ReportsTablePerGame from './ReportsTablePerGame'
import MyLabel from './MyLabel'
import ModalViewDownlines from '../Modals/ModalViewDownlines'
import ModalViewDownlinesPerGame from '../Modals/ModalViewDownlinesPerGame'

var userdata = ""
var usertypedownline = "coor"
const Reports = ({ token, LoggingOut }) => {
    const [startDate, setstartDate] = useState(parseDate(new Date()))
    const [endDate, setendDate] = useState(parseDate(new Date()))
    const [userType, setuserType] = useState(UserTypes[0])
    const [reports, setReports] = useState([])
    const [reptype, setReptype] = useState(ReportType[0])
    const [search, setSearch] = useState('')
    const [page, setpage] = useState(1)
    const [modalViewDownlines, setModalViewDownlines] = useState(false)
    const [modalViewDownlinesPerGame, setModalViewDownlinesPerGame] = useState(false)
    const onSelectUserType = (data) => {
        setuserType(data)
    }

    const toast = useToast()

    const SearchReports = () => {
        setReports([])
        FetchReports(page, token, reptype.short, userType.short, startDate, endDate, search).then(res => {
            if (res.data) {
                if (res.err) {
                    // toast({
                    //     title: "FETCH REPORT ERROR!",
                    //     description: res.data.message,
                    //     status: 'error',
                    //     duration: 3000,
                    //     isClosable: true,
                    //     position: 'top',
                    //     variant: 'left-accent'
                    // })
                } else {
                    setReports(res.data)
                }
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: "hOoops! There is something wrong in fetching the reports.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch((error) => {
            toast({
                title: "FETCH REPORT ERROR!",
                description: "Ooops! There is something wrong in fetching the reports.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }
    useEffect(() => {
        SearchReports()
    }, [startDate, endDate, userType, reptype, page])

    const onSelectReportType = (data) => {
        setReptype(data)
    }

    const onClickDownload = () => {
        FetchReportsall(token, reptype.short, userType.short, startDate, endDate, "").then(res => {
            if (res.data) {
                if (reptype.short === "overall") {
                    genPDF_overall(res.data, Queryparams.areanamepdf + " " + (userType ? userType.disp.toUpperCase() : " ") + " GAME GROSS OVERALL REPORT", startDate, endDate, userType)
                } else {
                    genPDF_pergame(res.data, Queryparams.areanamepdf + " " + (userType ? userType.disp.toUpperCase() : " ") + " GAME GROSS PER-GAME REPORT", startDate, endDate, userType)
                }
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: "hOoops! There is something wrong in fetching the reports.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch((error) => {
            toast({
                title: "FETCH REPORT ERROR!",
                description: "Ooops! There is something wrong in fetching the reports.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    const onViewDownline = (data) => {
        userdata = data
        switch (userType.short) {
            case "gencoor":
                usertypedownline = "coor"
                break;
            case "coor":
                usertypedownline = "subcoor"
                break;
            case "subcoor":
                usertypedownline = "teller"
                break;
            default:
                break;
        }
        setModalViewDownlines(true)
    }

    const onViewDownlineperGame = (data) => {
        userdata = data
        switch (userType.short) {
            case "gencoor":
                usertypedownline = "coor"
                break;
            case "coor":
                usertypedownline = "subcoor"
                break;
            case "subcoor":
                usertypedownline = "teller"
                break;
            default:
                break;
        }
        setModalViewDownlinesPerGame(true)
    }
    return (
        <Box flexDir={'column'}>
            {
                modalViewDownlines &&
                <ModalViewDownlines
                    isOpen={modalViewDownlines}
                    onClose={() => setModalViewDownlines(false)}
                    userdata={userdata}
                    usertypedownline={usertypedownline}
                    reptypedownline={reptype.short}
                    startDate={startDate}
                    endDate={endDate}
                    token={token}
                    LoggingOut={LoggingOut}
                />
            }
            {
                modalViewDownlinesPerGame &&
                <ModalViewDownlinesPerGame
                    isOpen={modalViewDownlinesPerGame}
                    onClose={() => setModalViewDownlinesPerGame(false)}
                    userdata={userdata}
                    usertypedownline={usertypedownline}
                    reptypedownline={reptype.short}
                    startDate={startDate}
                    endDate={endDate}
                    token={token}
                    LoggingOut={LoggingOut}
                />
            }
            <Box
                display={'flex'}
                flexDir={'row'}
                marginBottom={"50px"}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <MyReportType
                    selReportType={reptype}
                    onSelectReportType={onSelectReportType}
                />
                <Box w={'15px'} />
                <MyMenu
                    userType={userType}
                    users={UserTypes}
                    onSelectUserType={onSelectUserType}
                />
                <MyLabel
                    text={"Start :"}
                />
                <Input
                    color={'whiteAlpha.700'}
                    value={startDate}
                    bg={clrs.bglogin}
                    w={"200px"}
                    size="md"
                    max={parseDate(new Date())}
                    type="date"
                    borderWidth={0}
                    onChange={e => setstartDate(e.target.value)}
                />
                <MyLabel
                    text={"End :"}
                />
                <Input
                    color={'whiteAlpha.700'}
                    value={endDate}
                    bg={clrs.bglogin}
                    w={"200px"}
                    size="md"
                    max={parseDate(new Date())}
                    type="date"
                    borderWidth={0}
                    onChange={e => setendDate(e.target.value)}
                />
                <MyLabel
                    text={"Search :"}
                />
                <Input
                    color={'whiteAlpha.700'}
                    value={search}
                    bg={clrs.bglogin}
                    w={"120px"}
                    size="md"
                    borderWidth={0}
                    onChange={e => setSearch(e.target.value)}
                />
                <Button marginX={'15px'} onClick={() => SearchReports()}>
                    Search
                </Button>
                <Box display={"flex"} flexDir={"row"} p={2} justifyContent={'center'}>
                    <Button onClick={() => { page > 1 && setpage(page - 1) }}>{"<-"}</Button>
                    <Button w={50} marginInline={1} onClick={() => setpage(1)}>{page}</Button>
                    <Button onClick={() => setpage(page + 1)}>{"->"}</Button>
                </Box>
                <Button onClick={() => onClickDownload()}>
                    Download
                </Button>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                {
                    reptype.short === "overall" ?
                        <ReportsTableOverall
                            reports={reports}
                            userType={userType.short}
                            onViewDownline={onViewDownline}
                        />
                        :
                        <ReportsTablePerGame
                            reports={reports}
                            userType={userType.short}
                            onViewDownline={onViewDownlineperGame}
                        />
                }
            </Box>
        </Box>
    )
}

export default Reports