import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FaUsers } from 'react-icons/fa'
import { clrs } from '../Constants/Constants'

const UsersButton = ({ displayType, selGame, onSelNonGames }) => {
    return (
        displayType === "Users" ?
            <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"} marginX={"5px"}>
                <FaUsers size="30px" color={'white'} />
                <Text color={'white'} fontSize={"12px"}>Users</Text>
            </Flex>
            :
            <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"} marginX={"5px"}
                onClick={() => onSelNonGames("Users")}
                _hover={{
                    paddingX: "15px",
                    paddingY: "5px",
                    cursor: 'pointer',
                    fontSize: 20,
                    marginY: "5px",
                    marginX: '10px',
                    bg: clrs.white,
                    transition: '0.3s',
                    zIndex: 5,
                }}>
                <FaUsers size="30px" color={clrs.lable} />
                <Text color={clrs.lable} fontSize={"12px"}>Users</Text>
            </Flex>
    )
}

export default UsersButton