import React, { useState } from 'react'
import { Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import { AddAdmin } from '../Queries/Queries'

const ModalAddAdmin = ({ isOpen, onClose, token }) => {
    const [f_name, setf_name] = useState('')
    const [l_name, setl_name] = useState('')
    const [username, setusername] = useState('')
    const [password, setpassword] = useState('')
    const [mobile_num, setmobile_num] = useState('')
    const toast = useToast()

    const onClickAdd = () => {
        AddAdmin(token, f_name, l_name, username, password, mobile_num).then(res => {
            if (res.data) {
                toast({
                    title: "ADD ADMIN SUCCESS!",
                    description: "Successfully added an admin account!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
                onClose()
            } else {
                toast({
                    title: "ADD ADMIN ERROR!",
                    description: res.response.data.message ? res.response.data.message : "Ooops! There is something wrong in fetching the users.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch(() => {
            toast({
                title: "FETCH USERS ERROR!",
                description: "Ooops! There is something wrong in adding the admin.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={clrs.txtinptbrdr} color={'white'}>Add Admin Account</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <HStack>
                        <Text w={'50%'}>First Name:</Text>
                        <Input type='text' value={f_name} onChange={e => setf_name(e.target.value)} />
                    </HStack>
                    <HStack mt={5}>
                        <Text w={'50%'}>Last Name:</Text>
                        <Input type='text' value={l_name} onChange={e => setl_name(e.target.value)} />
                    </HStack>
                    <HStack mt={5}>
                        <Text w={'50%'}>Username:</Text>
                        <Input type='text' value={username} onChange={e => setusername(e.target.value)} />
                    </HStack>
                    <HStack mt={5}>
                        <Text w={'50%'}>Password:</Text>
                        <Input type='text' value={password} onChange={e => setpassword(e.target.value)} />
                    </HStack>
                    <HStack mt={5}>
                        <Text w={'50%'}>Mobile Number:</Text>
                        <Input type='text' value={mobile_num} onChange={e => setmobile_num(e.target.value)} placeholder='09561234568' />
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size={'md'}
                        colorScheme='green'
                        onClick={onClickAdd}
                        w={'130px'}
                        mr={5}
                        isDisabled={f_name.length === 0 || l_name.length === 0 || username.length === 0 || password.length === 0 || mobile_num.length !== 11}
                    >
                        Add Admin
                    </Button>
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalAddAdmin