import React from 'react'
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'

const ModalSignOut = ({ isOpen, onClose, onlogout }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={clrs.txtinptbrdr} color={'white'}>Log Out Confirmation</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <Text >Are you sure you want to log out?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size={'md'}
                        bg={clrs.txtinptbrdr}
                        color={'white'}
                        mr={3}
                        onClick={onlogout}
                        w={'130px'}
                        _hover={{ backgroundColor: clrs.bglogin }}
                    >
                        Log Me Out
                    </Button>
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalSignOut