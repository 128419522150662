import React from 'react'
import { clrs } from '../Constants/Constants'
import { Box, Text } from '@chakra-ui/react'

const GamesDsiplay = ({ games, onSelGame, selGame }) => {
    return (
        games.map((gm, idx) => (
            gm.gname.length > 0 &&
            <Box
                key={idx}
                onClick={() => onSelGame(gm)}
            >
                {
                    selGame.gname === gm.gname ?
                        <Text
                            paddingY={"5px"}
                            fontSize={15}
                            fontWeight={'700'}
                            color={'white'}
                            borderRadius={5}
                            paddingX={15}
                            bg={"#003173"}
                            textAlign={'center'}
                        >
                            {gm.gname.length > 0 ? gm.gname.toUpperCase() : "---"}
                        </Text>
                        :
                        <Text
                            borderRadius={5}
                            marginX={"5px"}
                            fontSize={15}
                            fontWeight={'700'}
                            color={clrs.lable}
                            bg={'blackAlpha.400'}
                            _hover={{
                                paddingX: "10px",
                                paddingY: "5px",
                                cursor: 'pointer',
                                fontSize: 20,
                                marginY: "10px",
                                bg: clrs.white,
                                transition: '0.3s',
                                zIndex: 5,
                            }}
                            textAlign={'center'}
                        >
                            {gm.gname.toUpperCase()}
                        </Text>
                }
            </Box>
        ))
    )
}

export default GamesDsiplay