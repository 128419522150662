import React, { useState } from 'react'
import { Table, TableContainer, Th, Thead, Tr, Tbody, Td, Button } from '@chakra-ui/react'
import { Converter } from '../Queries/Queries'
import { clrs } from '../Constants/Constants'

const ReportsTableOverall = ({ reports, userType, onViewDownline }) => {
    console.log("usertype", userType)
    return (
        <TableContainer height={'75vh'} overflowY={'scroll'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            <Table size='md' width={'400px'}>
                <Thead>
                    <Tr height={"50px"}>
                        <Th>Name</Th>
                        <Th>Username</Th>
                        <Th>Gross</Th>
                        <Th>Commission</Th>
                        <Th>Net</Th>
                        <Th>Hits</Th>
                        <Th>Amount Hits</Th>
                        <Th>Collectible</Th>
                        {
                            userType !== "teller" &&
                            <Th>Actions</Th>
                        }
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{''}</Td>
                        <Td>{''}</Td>
                        <Td textAlign={'right'}>{Converter(reports.gross ? reports.gross : 0)}</Td>
                        <Td textAlign={'right'}>{Converter(reports.comm ? reports.comm : 0)}</Td>
                        <Td textAlign={'right'}>{Converter(reports.net ? reports.net : 0)}</Td>
                        <Td textAlign={'right'}>{Converter(reports.hits ? reports.hits : 0)}</Td>
                        <Td textAlign={'right'}>{Converter(reports.hamt ? reports.hamt : 0)}</Td>
                        <Td color={reports.coll > 0 ? 'lime' : 'red'} textAlign={'right'}>{Converter(reports.coll ? reports.coll : 0)}</Td>
                    </Tr>
                    {
                        reports.per_down && reports.per_down.map((rprt, idx) => (
                            <Tr key={idx}>
                                <Td>{rprt.name}</Td>
                                <Td>{rprt.username}</Td>
                                <Td textAlign={'right'}>{Converter(rprt.gross)}</Td>
                                <Td textAlign={'right'}>{Converter(rprt.comm)}</Td>
                                <Td textAlign={'right'}>{Converter(rprt.net)}</Td>
                                <Td textAlign={'right'}>{Converter(rprt.hits)}</Td>
                                <Td textAlign={'right'}>{Converter(rprt.hamt)}</Td>
                                <Td color={rprt.coll > 0 ? 'lime' : 'red'} textAlign={'right'}>{Converter(rprt.coll)}</Td>
                                {
                                    userType !== "teller" &&
                                    <Td><Button onClick={() => {
                                        onViewDownline(rprt)
                                    }} colorScheme='green' size={'sm'}>Downlines</Button></Td>
                                }
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default ReportsTableOverall