import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'

const MyMenu = ({ users, userType, onSelectUserType }) => {
    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {userType.disp}
            </MenuButton>
            <MenuList>
                {
                    users && users.map((us) =>
                        <MenuItem key={us.short} onClick={() => onSelectUserType(us)}>{us.disp}</MenuItem>
                    )
                }
            </MenuList>
        </Menu>
    )
}

export default MyMenu