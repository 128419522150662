import React from 'react'
import { Table, TableContainer, Th, Thead, Tr, Tbody, Td, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'

const ModalFinancial = ({ isOpen, onClose, user }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={clrs.txtinptbrdr} color={'white'}>Financial details of {user.un}</ModalHeader>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <TableContainer height={"70vh"} overflowY={"scroll"}>
                        <Table size='sm' width={'500px'}>
                            <Thead>
                                <Tr height={"50px"}>
                                    <Th>Game</Th>
                                    <Th>Draw</Th>
                                    <Th>Percentage</Th>
                                    <Th>Mutliplier</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    user.game_fin && user.game_fin.map((us, idx) => (
                                        us.gname.length > 0 &&
                                        <Tr key={idx}>
                                            <Td>{us.gname}</Td>
                                            <Td>{us.gperiod}</Td>
                                            <Td textAlign={'right'}>{parseFloat(us.comm).toFixed(2) + "%"}</Td>
                                            <Td textAlign={'right'}>{"₱ " + parseFloat(us.win).toFixed(2)}</Td>
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>

                </ModalBody>
                <ModalFooter>
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalFinancial