import { Box } from '@chakra-ui/react'
import React from 'react'
import UsersButton from './UsersButton'
import ReportsButton from './ReportsButton'
// import ControlButton from './ControlButton'
import GamesDsiplay from './GamesDsiplay'
import { clrs } from '../Constants/Constants'

const Footer = ({ displayType, selGame, onSelNonGames, games, onSelGame, role }) => {
    return (
        <Box
            h={'7vh'}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            flexDir={'row'}
        >
            <Box
                w={'95vw'}
                overflowX={'auto'}
                marginLeft={2}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    '&::-webkit-scrollbar': {
                        height: '5px',
                        borderRadius: '2px',
                        backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        backgroundColor: clrs.lable,
                    },
                }}
            >
                <UsersButton
                    displayType={displayType}
                    selGame={selGame}
                    onSelNonGames={onSelNonGames}
                />
                <ReportsButton
                    displayType={displayType}
                    selGame={selGame}
                    onSelNonGames={onSelNonGames}
                />
                {/* <ControlButton
                    displayType={displayType}
                    selGame={selGame}
                    onSelNonGames={onSelNonGames}
                /> */}
                {
                    role !== 93 &&
                    <GamesDsiplay
                        games={games}
                        onSelGame={onSelGame}
                        selGame={selGame}
                    />
                }
            </Box >
        </Box>
    )
}

export default Footer