import React, { useState, useEffect } from 'react'
import MyMenu from './MyMenu'
import { FetchUsers } from '../Queries/Queries'
import { Box, Button, Input, useToast } from '@chakra-ui/react'
import UsersTable from './UsersTable'
import ModalFinancial from '../Modals/ModalFinancial'
import ModalAddAdmin from '../Modals/ModalAddAdmin'
import { clrs } from '../Constants/Constants'

export const UserTypes = [{
    short: "gencoor",
    disp: "General Coor"
}, {
    short: "coor",
    disp: "Coordinator"
}, {
    short: "subcoor",
    disp: "Sub-Coor"
}, {
    short: "teller",
    disp: "Tellers"
}]

var selectedUser = {}
const Users = ({ token, LoggingOut, rolID }) => {
    const [userType, setuserType] = useState(UserTypes[0])
    const [users, setusers] = useState([])
    const toast = useToast()
    const [showFinancialModal, setShowFinancialModal] = useState(false)
    const [showAddAdmin, setshowAddAdmin] = useState(false)
    const [page, setpage] = useState(1)
    const [searchname, setsearchname] = useState("")

    useEffect(() => {
        setusers([])
        FetchUsers(searchname, page, token, userType.short).then(res => {
            if (res.data) {
                setusers(res.data.requests)
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH USERS ERROR!",
                    description: res.response.data.message ? res.response.data.message : "Ooops! There is something wrong in fetching the users.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch(() => {
            toast({
                title: "FETCH USERS ERROR!",
                description: "Ooops! There is something wrong in fetching the users.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }, [userType, searchname, page])


    const onSelectUserType = (data) => {
        setuserType(data)
    }

    const onSelectUser = (data) => {
        selectedUser = data
        setShowFinancialModal(true)
    }

    const onClickAddAdmin = () => {
        setshowAddAdmin(true)
    }

    return (
        <Box flexDir={'column'}>
            {
                showFinancialModal &&
                <ModalFinancial
                    isOpen={showFinancialModal}
                    onClose={() => setShowFinancialModal(false)}
                    user={selectedUser}
                />
            }
            {
                showAddAdmin &&
                <ModalAddAdmin
                    isOpen={showAddAdmin}
                    onClose={() => setshowAddAdmin(false)}
                    user={selectedUser}
                    token={token}
                />
            }
            <Box display={"flex"} flexDir={"row"} justifyContent={"center"}>
                <MyMenu
                    userType={userType}
                    users={UserTypes}
                    onSelectUserType={onSelectUserType}
                />
                <Input
                    color={'whiteAlpha.700'}
                    value={searchname}
                    bg={clrs.bglogin}
                    w={"150px"}
                    size="md"
                    type="text"
                    borderWidth={0}
                    onChange={e => setsearchname(e.target.value)}
                    style={{ marginLeft: 5, marginBottom: 5 }}
                    placeholder='name'
                />
                <Box display={"flex"} flexDir={"row"} px={2} justifyContent={'center'}>
                    <Button onClick={() => { page > 1 && setpage(page - 1) }}>{"<-"}</Button>
                    <Button w={50} marginInline={1} onClick={() => setpage(1)}>{page}</Button>
                    <Button onClick={() => setpage(page + 1)}>{"->"}</Button>
                </Box>
            </Box>
            {
                rolID === 100 &&
                <Button colorScheme='teal' ml={5} onClick={() => onClickAddAdmin()}>Add Admin</Button>
            }
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                <UsersTable
                    users={users}
                    onSelectUser={onSelectUser}
                />
            </Box>
        </Box>

    )
}

export default Users