import React from 'react'
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text, Button } from '@chakra-ui/react'
import { clrs } from '../Constants/Constants'
import { commify } from '../Queries/Queries'

const TableHits = ({ setsearchtcode, hits }) => {
    return (
        <TableContainer height={'70vh'} overflowY={'scroll'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            <Table size='sm' color={'white'} >
                <Thead >
                    <Tr borderColor={clrs.lable} borderWidth={2} >
                        <Th color={'white'} py={3} ><Text>Winner/Tcode</Text></Th>
                        <Th color={'white'} py={3} ><Text>Combi</Text></Th>
                        <Th color={'white'} py={3} ><Text>Hits</Text></Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {
                        hits.length > 0 ? hits.map((ct, idx) => (
                            <Tr key={idx} borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td ><Text textAlign={'right'}  ><span>{ct.fn}</span> <br /><Button onClick={() => setsearchtcode(ct.tcod)} size={'sm'} style={{ color: 'green', width: '100%', marginTop: 5 }}>{ct.tcod}</Button></Text></Td>
                                <Td w={10} ><Text textAlign={'right'}  >{ct.combi}</Text></Td>
                                <Td w={10} ><Text textAlign={'right'}  ><span style={{ color: 'green' }}>{"₱ " + commify(parseFloat(ct.hits))}</span> <br /> <span style={{ color: 'lime', fontWeight: 'bold', fontSize: 15 }}>{"₱ " + commify(parseFloat(ct.hamt))}</span></Text></Td>
                            </Tr>
                        )) :
                            <Tr borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td color={'whiteAlpha.500'} textAlign={'center'} colSpan={3}>No hits Found.</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default TableHits