import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

export const ReportType = [
    {
        short: 'overall',
        disp: "OVERALL"
    }, {
        short: 'pergame',
        disp: "PER-GAME"
    }
]

const MyReportType = ({ selReportType, onSelectReportType }) => {
    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {selReportType.disp}
            </MenuButton>
            <MenuList>
                {
                    ReportType.map((us) =>
                        <MenuItem key={us.short} onClick={() => onSelectReportType(us)}>{us.disp}</MenuItem>
                    )
                }
            </MenuList>
        </Menu>
    )
}

export default MyReportType