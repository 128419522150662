import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { Converter } from '../Queries/Queries';
import { Queryparams } from '../Constants/Constants';

function mapOverAll(joa) {
    return joa.per_down.map(js => {
        return (
            [
                { text: js.name, style: 'tableBody', alignment: 'left' },
                { text: Converter(js.gross), style: 'tableBody' },
                { text: Converter(js.comm), style: 'tableBody' },
                { text: Converter(js.net), style: 'tableBody' },
                { text: Converter(js.hits), style: 'tableBody' },
                { text: Converter(js.hamt), style: 'tableBody' },
                { text: Converter(js.coll), style: 'tableBody' },
            ]
        )
    })
}


function mapPerGameHead(joa) {
    return joa.map(js => {
        return (
            [
                { text: js.gname + " " + js.gperiod, style: 'tableBody', alignment: 'left' },
                { text: Converter(js.gross), style: 'tableBody' },
                { text: Converter(js.comm), style: 'tableBody' },
                { text: Converter(js.net), style: 'tableBody' },
                { text: Converter(js.hits), style: 'tableBody' },
                { text: Converter(js.hamt), style: 'tableBody' },
                { text: Converter(js.coll), style: 'tableBody' },
            ]
        )
    })
}

function tablePerGame(joa) {
    return joa.map(js => {
        return (
            {
                style: 'tableExample',
                table: {
                    widths: ['*', 95, 95, 95, 95, 95, 95],
                    body: [
                        [
                            {
                                text: js.name + " " + js.dt, colSpan: 7, bold: true, fontSize: 10, alignment: 'left'
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            { text: 'Name', style: 'tableHeader' },
                            { text: 'Gross', style: 'tableHeader' },
                            { text: 'Commission', style: 'tableHeader' },
                            { text: 'Net', style: 'tableHeader' },
                            { text: 'Unit Hits', style: 'tableHeader' },
                            { text: 'Amount Hits', style: 'tableHeader' },
                            { text: 'Collectibles', style: 'tableHeader' },
                        ],
                        ...mapPerGameHead(js.per_game)
                    ]
                }
            }
        )
    })
}

export function genPDF_overall(jsonIn, filename, startdate, enddate, utype) {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    const documentDefinition = {
        footer: function (currentPage, pageCount) {
            return { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [40, 0] };
        },
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            {
                text: Queryparams.areanamepdf + " " + utype.disp.toUpperCase() + ' GAME GROSS OVERALL REPORT',
                style: 'header',
                alignment: 'center'
            },
            {
                text: "Date Covered: " + startdate + " to " + enddate,
                style: "subheader",
                alignment: 'center'
            },
            '\n',
            {
                style: 'tableExample',
                table: {
                    widths: ['*', 95, 95, 95, 95, 95, 95],
                    body: [
                        [
                            { text: 'Name', style: 'tableHeader' },
                            { text: 'Gross', style: 'tableHeader' },
                            { text: 'Commission', style: 'tableHeader' },
                            { text: 'Net', style: 'tableHeader' },
                            { text: 'Unit Hits', style: 'tableHeader' },
                            { text: 'Amount Hits', style: 'tableHeader' },
                            { text: 'Collectibles', style: 'tableHeader' },
                        ],
                        [
                            { text: 'Total', style: 'tableHeader' },
                            { text: Converter(jsonIn.gross), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.comm), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.net), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.hits), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.hamt), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.coll), style: 'tableBody', fillColor: 'lightgray' },
                        ],
                        ...mapOverAll(jsonIn)
                    ]
                }
            },

        ],
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 9,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                fontSize: 9,
            },
            tableHeader: {
                bold: true,
                fontSize: 9,
                color: 'black',
                alignment: 'center',
                fillColor: 'lightgray'
            },
            tableBody: {
                bold: false,
                fontSize: 8,
                alignment: 'right',
            },
            coorname: {
                bold: true,
                fontSize: 12
            }
        }
    };
    pdfMake.createPdf(documentDefinition).download(filename + " " + startdate + " to " + enddate + '.pdf');
    // pdfMake.createPdf(documentDefinition).open();
}


export function genPDF_overall_downline(jsonIn, filename, startdate, enddate, utype) {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    const documentDefinition = {
        footer: function (currentPage, pageCount) {
            return { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [40, 0] };
        },
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            {
                text: utype.toUpperCase() + '\'s DOWNLINE GAME GROSS OVERALL REPORT',
                style: 'header',
                alignment: 'center'
            },
            {
                text: Queryparams.areanamepdf,
                style: 'header',
                alignment: 'center'
            },
            {
                text: "Date Covered: " + startdate + " to " + enddate,
                style: "subheader",
                alignment: 'center'
            },
            '\n',
            {
                style: 'tableExample',
                table: {
                    widths: ['*', 95, 95, 95, 95, 95, 95],
                    body: [
                        [
                            { text: 'Name', style: 'tableHeader' },
                            { text: 'Gross', style: 'tableHeader' },
                            { text: 'Commission', style: 'tableHeader' },
                            { text: 'Net', style: 'tableHeader' },
                            { text: 'Unit Hits', style: 'tableHeader' },
                            { text: 'Amount Hits', style: 'tableHeader' },
                            { text: 'Collectibles', style: 'tableHeader' },
                        ],
                        [
                            { text: 'Total', style: 'tableHeader' },
                            { text: Converter(jsonIn.gross), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.comm), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.net), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.hits), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.hamt), style: 'tableBody', fillColor: 'lightgray' },
                            { text: Converter(jsonIn.coll), style: 'tableBody', fillColor: 'lightgray' },
                        ],
                        ...mapOverAll(jsonIn)
                    ]
                }
            },

        ],
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 9,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                fontSize: 9,
            },
            tableHeader: {
                bold: true,
                fontSize: 9,
                color: 'black',
                alignment: 'center',
                fillColor: 'lightgray'
            },
            tableBody: {
                bold: false,
                fontSize: 8,
                alignment: 'right',
            },
            coorname: {
                bold: true,
                fontSize: 12
            }
        }
    };
    pdfMake.createPdf(documentDefinition).download(filename + " " + startdate + " to " + enddate + '.pdf');
    // pdfMake.createPdf(documentDefinition).open();
}


export function genPDF_pergame(jsonIn, filename, startdate, enddate, utype) {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    const documentDefinition = {
        footer: function (currentPage, pageCount) {
            return { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [40, 0] };
        },
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            {
                text: Queryparams.areanamepdf + " " + utype.disp.toUpperCase() + ' PER-GAME REPORT',
                style: 'header',
                alignment: 'center'
            },
            {
                text: "Date Covered: " + startdate + " to " + enddate,
                style: "subheader",
                alignment: 'center'
            },
            '\n',
            ...tablePerGame(jsonIn)
        ],
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 9,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                fontSize: 9,
            },
            tableHeader: {
                bold: true,
                fontSize: 9,
                color: 'black',
                alignment: 'center',
                fillColor: 'lightgray'
            },
            tableBody: {
                bold: false,
                fontSize: 8,
                alignment: 'right',
            }
        }
    };
    pdfMake.createPdf(documentDefinition).download(filename + " " + startdate + " to " + enddate + '.pdf');
    // pdfMake.createPdf(documentDefinition).open();
}

export function genPDF_pergame_downline(jsonIn, filename, startdate, enddate, utype) {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    const documentDefinition = {
        footer: function (currentPage, pageCount) {
            return { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [40, 0] };
        },
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            {
                text: utype.toUpperCase() + '\'s DOWNLINE PER-GAME REPORT',
                style: 'header',
                alignment: 'center'
            },
            {
                text: Queryparams.areanamepdf,
                style: 'header',
                alignment: 'center'
            },
            {
                text: "Date Covered: " + startdate + " to " + enddate,
                style: "subheader",
                alignment: 'center'
            },
            '\n',
            ...tablePerGame(jsonIn)
        ],
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 9,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                fontSize: 9,
            },
            tableHeader: {
                bold: true,
                fontSize: 9,
                color: 'black',
                alignment: 'center',
                fillColor: 'lightgray'
            },
            tableBody: {
                bold: false,
                fontSize: 8,
                alignment: 'right',
            }
        }
    };
    pdfMake.createPdf(documentDefinition).download(filename + " " + startdate + " to " + enddate + '.pdf');
    // pdfMake.createPdf(documentDefinition).open();
}

//COMBINATION-AMOUNT PDF
function mapPerCombi(cv1, cv2, cv3, cv4, ta1, ta2, ta3, ta4) {
    return cv1.map((value, index) => {
        return (
            [
                { text: value, style: 'tableBody', alignment: 'center' },
                { text: Converter(ta1[index]), style: 'tableBody' },
                { text: cv2[index], style: 'tableBody', alignment: 'center' },
                { text: Converter(ta2[index]), style: 'tableBody' },
                { text: cv3[index], style: 'tableBody', alignment: 'center' },
                { text: Converter(ta3[index]), style: 'tableBody' },
                { text: cv4[index], style: 'tableBody', alignment: 'center' },
                { text: Converter(ta4[index]), style: 'tableBody' }
            ]
        )
    })
}

export function genPDF_combi(jsonIn, filename, dateRange, dateDL, gcode) {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    var combiValue1 = [];
    var combiValue2 = [];
    var combiValue3 = [];
    var combiValue4 = [];
    var totalAmount1 = [];
    var totalAmount2 = [];
    var totalAmount3 = [];
    var totalAmount4 = [];

    var jsonDataSize = jsonIn.details.length
    jsonIn.details.map((currentElement, index) => {
        if ((index + 1) % 152 === 0) {
            combiValue4.push(currentElement.combi);
            totalAmount4.push(currentElement.bet);
        } else if ((index + 1) % 152 <= 38) {
            combiValue1.push(currentElement.combi);
            totalAmount1.push(currentElement.bet);
        } else if ((index + 1) % 152 <= 76) {
            combiValue2.push(currentElement.combi);
            totalAmount2.push(currentElement.bet);
        } else if ((index + 1) % 152 <= 114) {
            combiValue3.push(currentElement.combi);
            totalAmount3.push(currentElement.bet);
        } else {
            combiValue4.push(currentElement.combi);
            totalAmount4.push(currentElement.bet);
        }

        if ((index + 1) === jsonDataSize) {
            for (var i = combiValue2.length - 1; i < combiValue1.length; i++) {
                combiValue2.push('');
                totalAmount2.push('');
            }

            for (var i1 = combiValue3.length - 1; i1 < combiValue1.length; i1++) {
                combiValue3.push('');
                totalAmount3.push('');
            }

            for (var i2 = combiValue4.length - 1; i2 < combiValue1.length; i2++) {
                combiValue4.push('');
                totalAmount4.push('');
            }
        }
        return null
    });

    const documentDefinition = {
        pageMargins: [40, 130, 40, 50],
        header: function () {
            return {
                columns: [
                    {
                        stack: [
                            {
                                text: Queryparams.areanamepdf + " " + gcode.toUpperCase() + ' SUMMARY OF BETS',
                                style: 'header',
                                alignment: 'center'
                            },
                            '\n',
                            {
                                text: "Draw Generated: " + dateDL,
                                style: "subheader",
                                alignment: 'left'
                            },
                            {
                                text: "Draw Date: " + dateRange,
                                style: "subheader",
                                alignment: 'left'
                            },
                            {
                                text: "Grand Total: " + Converter(jsonIn.total),
                                style: "subheader",
                                alignment: 'left'
                            }
                        ],
                        margin: [40, 40]
                    }
                ]
            }
        },
        footer: function (currentPage, pageCount) {
            return { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [40, 0] };
        },
        pageSize: 'A4',
        pageOrientation: 'portrait',
        content: [
            {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                        [
                            { text: 'Combi - Amount', style: 'tableHeader', colSpan: 2 }, {},
                            { text: 'Combi - Amount', style: 'tableHeader', colSpan: 2 }, {},
                            { text: 'Combi - Amount', style: 'tableHeader', colSpan: 2 }, {},
                            { text: 'Combi - Amount', style: 'tableHeader', colSpan: 2 }, {}
                        ],
                        ...mapPerCombi(combiValue1, combiValue2, combiValue3, combiValue4, totalAmount1, totalAmount2, totalAmount3, totalAmount4)
                    ]
                }
            },

        ],
        styles: {
            header: {
                fontSize: 14,
                bold: true,
            },
            subheader: {
                fontSize: 12,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 15],
                fontSize: 12,
            },
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black',
                alignment: 'center'
            },
            tableBody: {
                bold: false,
                fontSize: 10,
                alignment: 'right'
            }
        }
    };

    pdfMake.createPdf(documentDefinition).download(filename + " " + dateRange + ".pdf");
    // pdfMake.createPdf(documentDefinition).open();
}