import { Box, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import TableControl from './TableControl';
import { FetchControls } from '../Queries/Queries';
import { Queryparams } from '../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import ModalAddControl from '../Modals/ModalAddControl';
import ModalUpdateControl from '../Modals/ModalUpdateControl';
import ModalRemoveControl from '../Modals/ModalRemoveControl';

var selctrl = {}
const ControlForm = ({ selGame, token }) => {
    const [controls, setControls] = useState([])

    const [modalEditCtrl, setModalEditCtrl] = useState(false)
    const [modalRemoveCtrl, setModalRemoveCtrl] = useState(false)
    const [modalAddCtrl, setModalAddCtrl] = useState(false)
    const navigate = useNavigate()
    const toast = useToast()

    const getControls = () => {
        FetchControls(selGame.gcode, Queryparams.url, token).then(res => {
            if (res.data) {
                setControls(res.data)
            } else if (res.status === 401) {
                localStorage.removeItem("reduxPersistAuth")
                navigate("/login")
            } else {
                setControls([])
                toast({
                    title: "FETCH CONTROLS ERROR!",
                    description: res.response.data.message ? res.response.data.message : "Ooops! There is something wrong fetching the game controls.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch(() => {
            setControls([])
            toast({
                title: "FETCH CONTROLS ERROR!",
                description: "Ooops! There is something wrong fetching the game controls.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    const onSelectControl = (data) => {
        selctrl = data
        setModalEditCtrl(true)
    }

    const onSelectRemoveControl = (data) => {
        selctrl = data
        setModalRemoveCtrl(true)
    }

    const onSelectAddControl = () => {
        setModalAddCtrl(true)
    }

    const onDoneAddControl = () => {
        setModalAddCtrl(false)
        getControls()
    }

    const onDoneUpdate = () => {
        setModalEditCtrl(false)
        getControls()
    }

    const onDoneRemoveUpdate = () => {
        setModalRemoveCtrl(false)
        getControls()
    }

    useEffect(() => {
        selGame.url && getControls()
    }, [selGame])

    return (
        <Box marginX={"10px"}>
            {
                modalAddCtrl &&
                <ModalAddControl
                    isOpen={modalAddCtrl}
                    onClose={() => setModalAddCtrl(false)}
                    data={selGame}
                    token={token}
                    onDoneUpdate={() => onDoneAddControl()}
                />
            }
            {
                modalEditCtrl &&
                <ModalUpdateControl
                    isOpen={modalEditCtrl}
                    onClose={() => setModalEditCtrl(false)}
                    data={selctrl}
                    url={selGame.url}
                    token={token}
                    onDoneUpdate={() => onDoneUpdate()}
                />
            }

            {
                modalRemoveCtrl &&
                <ModalRemoveControl
                    isOpen={modalRemoveCtrl}
                    onClose={() => setModalRemoveCtrl(false)}
                    data={selctrl}
                    token={token}
                    onDoneUpdate={() => onDoneRemoveUpdate()}
                />
            }
            <Box width={'350px'}>
                <TableControl
                    controls={controls}
                    onSelectControl={onSelectControl}
                    onSelectRemoveControl={onSelectRemoveControl}
                    onSelectAddControl={onSelectAddControl}
                />
            </Box>
        </Box>

    )
}

export default ControlForm