import { Box, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import TableCombis from './TableCombis';
import { FetchCombis } from '../Queries/Queries';

const CombiAmountForm = ({ selGame, token }) => {
    const [combis, setCombis] = useState(null)
    const toast = useToast()

    const GetCombis = () => {
        setCombis(null)
        selGame.gcode && FetchCombis(token, selGame.gcode).then(res => {
            if (res.err) {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: res.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            } else {
                setCombis(res.data)
            }
        }).catch()
    }

    useEffect(() => {
        GetCombis()
    }, [selGame.gcode])

    return (
        <Box marginLeft={'20px'}>
            <TableCombis
                selGame={selGame}
                combis={combis}
            />
        </Box>
    )
}

export default CombiAmountForm