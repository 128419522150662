import React, { useEffect, useState } from 'react'
import { Queryparams } from '../Constants/Constants'
import { FetchHits } from '../Queries/Queries'
import { Box, Button, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import TableHits from './TableHits'

const WinnersForm = ({ setsearchtcode, searchdate, selGame, token }) => {
    const [hits, sethits] = useState([])
    const toast = useToast()
    const navigate = useNavigate()
    const [page, setpage] = useState(1)

    const getHits = () => {
        sethits([])
        FetchHits(selGame.gcode, searchdate, page, Queryparams.url, token).then(res => {
            if (res.data) {
                sethits(res.data)
            } else if (res.status === 401) {
                localStorage.removeItem("reduxPersistAuth")
                navigate("/login")
            }
        }).catch(() => {
            sethits([])
            toast({
                title: "FETCH ENTRIES ERROR!",
                description: "Ooops! There is something wrong fetching entries.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    useEffect(() => {
        getHits()
    }, [selGame.gcode, searchdate, page])

    return (
        <Box border={"1px solid blue"} ml={5} borderRadius={5}>
            <TableHits
                hits={hits}
                setsearchtcode={setsearchtcode}
            />
            <Box display={"flex"} flexDir={"row"} p={2} justifyContent={'center'}>
                <Button onClick={() => { page > 1 && setpage(page - 1) }}>{"<-"}</Button>
                <Button w={50} marginInline={1} onClick={() => setpage(1)}>{page}</Button>
                <Button onClick={() => setpage(page + 1)}>{"->"}</Button>
            </Box>
        </Box>
    )
}

export default WinnersForm