import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box
} from '@chakra-ui/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Protected from './components/Protected/Protected';
import { Queryparams } from './components/Constants/Constants';
import LoadingComponent from './components/Loading/LoadingComponent';
import { FetchGames } from './components/Queries/Queries';
var CryptoJS = require("crypto-js");

var Lstore = ""
// var wsdata = ""
function App() {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    localStorage.setItem("chakra-ui-color-mode", "dark")
    setLoading(true)
    try {
      var bytes = CryptoJS.AES.decrypt(localStorage.getItem('reduxPersistAuth'), Queryparams.powerkey);
      Lstore = bytes.toString(CryptoJS.enc.Utf8);
      FetchGames(JSON.parse(Lstore).at).then(res => {
        if (res.err) {
          setTimeout(() => {
            setIsSignedIn(false)
            setLoading(false)
            localStorage.removeItem("reduxPersistAuth")
          }, 1000);
        } else {
          setTimeout(() => {
            setIsSignedIn(true)
            setLoading(false)
          }, 1000);
        }
      }).catch(() => {
        setTimeout(() => {
          setIsSignedIn(false)
          setLoading(false)
        }, 1000);
      })
    } catch (err) {
      setTimeout(() => {
        setIsSignedIn(false)
        setLoading(false)
      }, 1000);
    }
  }, [])

  return (
    <ChakraProvider>
      {
        loading ?
          <Box bg={"#001026"} h={'100vh'} justifyContent={'center'} alignItems={'center'} display={'flex'}>
            <LoadingComponent />
          </Box>
          :
          <BrowserRouter>
            <Routes >
              <Route exact path="/login" name="Login Page" element={<Login setIsSignedIn={setIsSignedIn} />} />
              <Route exact path="/" name="Home" element={
                <Protected isSignedIn={isSignedIn}>
                  <Home />
                </Protected>
              } />
              <Route
                path="*"
                element={<Navigate to="/" replace />}
              />
            </Routes >
          </BrowserRouter>
      }

    </ChakraProvider>
  );
}


export default App;