import { Box, Button } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'

const Control = ({ token, LoggingOut }) => {
    // const ws = new WebSocket("wss://websocks.msd4p.com/ws");

    // useEffect(() => {
    //     return () => {
    //         ws.close()
    //     }
    // }, [])



    // ws.onopen = () => {
    //     ws.send(JSON.stringify({
    //         "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTAxMDUxNTEsInN1YnMiOnsic2RzLTJkMSI6bnVsbCwic2RzLTJwMSI6bnVsbCwic2RzLTNkMSI6bnVsbCwic2RzLTRkbiI6bnVsbCwic2RzLXAzbiI6bnVsbCwic2RzLXBvbTEiOm51bGwsInNkcy1wb21ydjEiOm51bGx9fQ.S4YiTmp9NCHzChFbAjBHFBUHgPCufQjzqTwIb3Ss6qs"
    //     }));
    // };

    // ws.onmessage = function (event) {
    //     const json = JSON.parse(event.data);
    //     try {
    //         if ((json.event = "data")) {
    //             console.log(json)
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    return (
        <Box>

        </Box>
    )
}

export default Control