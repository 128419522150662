import { Table, TableContainer, Th, Thead, Tr, Tbody, Td, Tooltip, IconButton } from '@chakra-ui/react'
import React from 'react'
import { FaMoneyBillWave } from 'react-icons/fa'
import { clrs } from '../Constants/Constants'

const UsersTable = ({ users, onSelectUser }) => {
    return (
        <TableContainer height={'80vh'} overflowY={'scroll'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            <Table size='md' width={'400px'}>
                <Thead>
                    <Tr height={"50px"}>
                        <Th>Full Name</Th>
                        <Th>Username</Th>
                        <Th>Mobile No.</Th>
                        <Th>Financials</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        (users && users.length > 0) ? users.map((us, idx) => (
                            <Tr key={idx}>
                                <Td>{us.fn + " " + us.ln}</Td>
                                <Td>{us.un}</Td>
                                <Td>{us.mob_no}</Td>
                                <Td textAlign={'center'}>
                                    <Tooltip
                                        label="View Financials"
                                        placement="right"
                                    >
                                        <IconButton
                                            onClick={() => onSelectUser(us)}
                                            marginLeft={"5px"}
                                            boxSize={"30px"}
                                            _hover={{
                                                backgroundColor: 'whiteAlpha.400'
                                            }}
                                            size={'xs'}
                                            bg={'transparent'}
                                            icon={
                                                <FaMoneyBillWave
                                                    color='green'
                                                    size={"25px"}
                                                />
                                            }
                                            color={'white'}
                                        />
                                    </Tooltip>
                                </Td>
                            </Tr>
                        ))
                            :
                            <Tr borderColor={clrs.lable} borderWidth={'2px'}>
                                <Td color={'whiteAlpha.500'} textAlign={'center'} colSpan={3}>No users Found.</Td>
                            </Tr>
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default UsersTable