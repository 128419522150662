import React, { useState, useEffect } from 'react'
import { Box, Button, useToast } from '@chakra-ui/react'
import { FetchEntries } from '../Queries/Queries'
import { useNavigate } from 'react-router-dom'
import { Queryparams } from '../Constants/Constants'
import TablerEntries from './TablerEntries'

const EntriesForm = ({ searchtcode, searchname, searchdate, selGame, token, newEntry, realtime }) => {
    const [entries, setentries] = useState([])
    const navigate = useNavigate()
    const toast = useToast()
    const [page, setpage] = useState(1)

    const getEntries = () => {
        setentries([])
        FetchEntries(selGame.gcode, searchdate, searchtcode, searchname, page, Queryparams.url, token).then(res => {
            if (res.data) {
                setentries(res.data)
            } else if (res.status === 401) {
                localStorage.removeItem("reduxPersistAuth")
                navigate("/login")
            }
        }).catch(() => {
            setentries([])
            toast({
                title: "FETCH ENTRIES ERROR!",
                description: "Ooops! There is something wrong fetching entries.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    useEffect(() => {
        if (realtime) {
            entries.pop()
            setentries([newEntry, ...entries])
        }
    }, [newEntry])


    useEffect(() => {
        getEntries()
    }, [selGame.gcode, searchdate, searchtcode, searchname, page, realtime])

    return (
        <Box border={"1px solid blue"} w={"800px"} ml={5} borderRadius={5}>
            <TablerEntries
                entries={entries}
            />
            <Box display={"flex"} flexDir={"row"} p={2} justifyContent={'center'}>
                <Button onClick={() => { page > 1 && setpage(page - 1) }}>{"<-"}</Button>
                <Button w={50} marginInline={1} onClick={() => setpage(1)}>{page}</Button>
                <Button onClick={() => setpage(page + 1)}>{"->"}</Button>
            </Box>
        </Box>
    )
}

export default EntriesForm