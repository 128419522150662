import React from 'react'
import { Table, TableContainer, Th, Thead, Tr, Tbody, Td, Button } from '@chakra-ui/react'
import { Converter } from '../Queries/Queries'
import { clrs } from '../Constants/Constants'

const ReportsPerGame = ({ reports }) => {
    return (
        <TableContainer height={'65vh'} overflowY={'scroll'}
            sx={{
                '&::-webkit-scrollbar': {
                    height: '1px',
                    width: '5px',
                    borderRadius: '2px',
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                    // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                    backgroundColor: clrs.lable,
                },
            }}
        >
            {
                reports.length > 0 && reports.map((rprt, idx) => (
                    <Table size='sm' style={{ minWidth: '900px', marginTop: '20px' }}>
                        <Thead >
                            <Tr key={idx} bg={clrs.bglogin} height={'40px'}>
                                <Th colSpan={4} whiteSpace={'normal'} fontSize={'20px'}>{rprt.name}</Th>
                                <Th colSpan={4} fontSize={'15px'}>{rprt.dt}</Th>
                            </Tr>
                        </Thead>
                        <Thead >
                            <Tr height={"50px"} width={"100%"}>
                                <Th>Game</Th>
                                <Th>Draw</Th>
                                <Th>Gross</Th>
                                <Th>Commission</Th>
                                <Th>Net</Th>
                                <Th>Hits</Th>
                                <Th>Amount Hits</Th>
                                <Th>Collectible</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                rprt.per_game && rprt.per_game.map((rprtpg, idx) => (
                                    <Tr key={idx}>
                                        <Td>{rprtpg.gname}</Td>
                                        <Td>{rprtpg.gperiod}</Td>
                                        <Td textAlign={'right'}>{Converter(rprtpg.gross)}</Td>
                                        <Td textAlign={'right'}>{Converter(rprtpg.comm)}</Td>
                                        <Td textAlign={'right'}>{Converter(rprtpg.net)}</Td>
                                        <Td textAlign={'right'}>{Converter(rprtpg.hits)}</Td>
                                        <Td textAlign={'right'}>{Converter(rprtpg.hamt)}</Td>
                                        <Td color={rprtpg.coll > 0 ? 'lime' : 'red'} textAlign={'right'}>{Converter(rprtpg.coll)}</Td>
                                    </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                ))
            }


        </TableContainer>
    )
}

export default ReportsPerGame