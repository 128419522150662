import { Box } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { Queryparams, clrs } from '../Constants/Constants'
import { useNavigate } from 'react-router-dom'
import { FetchGames } from '../Queries/Queries';
import ModalSignOut from '../Modals/ModalSignOut';
import Footer from './Footer';
import Header from './Header';
import Body from './Body';
var CryptoJS = require("crypto-js");

var Lstore = ""
var user = ""
var userID = 0
var wstoken = ""
var running = null
const Home = () => {
    const [modalLogout, setModalLogout] = useState(false)
    const navigate = useNavigate()
    const [games, setGames] = useState([])
    const [selGame, setSelGame] = useState({})
    const [displayType, setDisplayType] = useState("games") //games, results


    useEffect(() => {
        try {
            var bytes = CryptoJS.AES.decrypt(localStorage.getItem('reduxPersistAuth'), Queryparams.powerkey);
            Lstore = bytes.toString(CryptoJS.enc.Utf8);
            user = JSON.parse(Lstore).at;
            userID = JSON.parse(Lstore).rol;
            wstoken = JSON.parse(Lstore).ws;

            FetchGames(user).then(res => {
                setGames(res.data)
                setSelGame(res.data[0])
            }).catch((err) => {

            })
            setDisplayType("Reports")
        } catch (err) {
            navigate("/")
        }
    }, [navigate])

    const onSelGame = (data) => {
        setDisplayType("games")
        setSelGame(data)
        // localStorage.setItem('game', CryptoJS.AES.encrypt(JSON.stringify(data.gcode), Queryparams.powerkey).toString());
        localStorage.setItem('game', data.gcode);
    }

    const LoggingOut = () => {
        localStorage.removeItem("reduxPersistAuth")
        navigate("/login")
    }



    const onSelNonGames = (data) => {
        setDisplayType(data)
        setSelGame([])
    }

    return (
        <Box h={'100vh'} bg={clrs.bglogin} justifyContent={'center'} alignItems={'center'}>
            {
                modalLogout &&
                <ModalSignOut
                    isOpen={modalLogout}
                    onClose={() => setModalLogout(false)}
                    onlogout={() => LoggingOut()}
                />
            }
            <Header
                Lstore={Lstore}
                setModalLogout={setModalLogout}
            />
            <Body
                combis={running}
                selGame={selGame}
                displayType={displayType}
                LoggingOut={LoggingOut}
                token={user}
                wstoken={wstoken}
                rolID={userID}
            />
            <Footer
                displayType={displayType}
                selGame={selGame}
                onSelNonGames={onSelNonGames}
                games={games}
                onSelGame={onSelGame}
                role={userID}
            />
        </Box>
    )
}

export default Home