import { Box } from '@chakra-ui/react'
import React from 'react'
// import TableControl from './TableControl'
import Users from './Users'
import Reports from './Reports'
import Control from './Control'
import GamesForm from './GamesForm'

const Body = ({ combis, running, selGame, displayType, token, LoggingOut, wstoken, rolID }) => {

    return (
        <Box justifyContent={'center'} alignItems={'center'} w={'100%'}>
            <Box h={'88vh'} bg={'#003173'} borderTopLeftRadius={10} borderTopRightRadius={10} padding={2} >
                {
                    displayType === "Users" ?
                        <Users token={token} LoggingOut={LoggingOut} rolID={rolID} />
                        :
                        displayType === "Reports" ?
                            <Reports token={token} LoggingOut={LoggingOut} />
                            :
                            displayType === "Control" ?
                                <Control token={token} LoggingOut={LoggingOut} />
                                :
                                <GamesForm
                                    combis={combis}
                                    running={running}
                                    wstoken={wstoken}
                                    selGame={selGame}
                                    token={token}
                                />
                }
            </Box>
        </Box>
    )
}

export default Body