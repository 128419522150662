import React, { useState } from 'react'
import { Box, Button, HStack, Input } from '@chakra-ui/react'
import RunningForm from './RunningForm'
import CombiAmountForm from './CombiAmountForm'
import ControlForm from './ControlForm'
import EntriesForm from './EntriesForm'
import WinnersForm from './WinnersForm'
import { parseDate } from '../Queries/Queries'
import { clrs } from '../Constants/Constants'


const GamesForm = ({ token, selGame, wstoken }) => {
    const [searchdate, setsearchdate] = useState(parseDate(new Date()))
    const [searchtcode, setsearchtcode] = useState('')
    const [searchname, setsearchname] = useState('')
    const [newEntry, setnewEntry] = useState({})
    const [realtime, setrealtime] = useState(true)
    return (
        <Box display={'flex'} flexDir={'row'} padding={10} justifyContent={'center'}>
            {/* <CombiAmountForm
                selGame={selGame}
                token={token}
            /> */}
            <ControlForm
                selGame={selGame}
                token={token}
            />
            <RunningForm
                selGame={selGame}
                token={token}
                wstoken={wstoken}
                setnewEntry={setnewEntry}
            />
            <Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <HStack style={{ alignItems: 'center' }}>
                        <Input
                            color={'whiteAlpha.700'}
                            value={searchdate}
                            bg={clrs.bglogin}
                            w={"150px"}
                            size="md"
                            max={parseDate(new Date())}
                            type="date"
                            borderWidth={0}
                            onChange={e => setsearchdate(e.target.value)}
                            style={{ marginLeft: 20, marginBottom: 5 }}
                        />
                        <Input
                            color={'whiteAlpha.700'}
                            value={searchtcode}
                            bg={clrs.bglogin}
                            w={"150px"}
                            size="md"
                            type="text"
                            borderWidth={0}
                            onChange={e => setsearchtcode(e.target.value)}
                            style={{ marginLeft: 5, marginBottom: 5 }}
                            placeholder='tcode'
                        />
                        <Button size={'sm'} colorScheme='red' marginX={1} mb={1} onClick={() => setsearchtcode("")}>x</Button>
                        <Input
                            color={'whiteAlpha.700'}
                            value={searchname}
                            bg={clrs.bglogin}
                            w={"150px"}
                            size="md"
                            type="text"
                            borderWidth={0}
                            onChange={e => setsearchname(e.target.value)}
                            style={{ marginLeft: 10, marginBottom: 5 }}
                            placeholder='name, username'
                        />
                        <Button size={'sm'} colorScheme='red' marginX={1} mb={1} onClick={() => setsearchname("")}>x</Button>
                    </HStack>
                    <Button size={'sm'} colorScheme={realtime ? 'green' : 'red'} marginX={1} mt={1} onClick={() => setrealtime(!realtime)}>{realtime ? "Realtime ON" : "Realtime OFF"}</Button>

                </Box>
                <Box display={'flex'} flexDir={'row'}>
                    <EntriesForm
                        searchname={searchname}
                        searchtcode={searchtcode}
                        searchdate={searchdate}
                        selGame={selGame}
                        token={token}
                        newEntry={newEntry}
                        realtime={realtime}
                    />
                    <WinnersForm
                        setsearchtcode={setsearchtcode}
                        searchdate={searchdate}
                        selGame={selGame}
                        token={token}
                    />
                </Box>
            </Box>

        </Box>

    )
}

export default GamesForm